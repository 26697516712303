@media screen and (min-width: 960px) {
  #nos_producteurs .p {
    margin-bottom: 10vh;
  }
  #nos_producteurs .fond {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: auto;
    background-image: url(./../img/fond-prez_corps.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: fit-content;
    width: 800px;
  }
  #nos_producteurs .fond .column {
    display: flex;
    flex-direction: column;
    width: 38%;
    justify-content: right;
  }
  #nos_producteurs .fond .column button {
    border: none;
    background-color: transparent;
  }
  #nos_producteurs .fond .column .producteur {
    height: fit-content;
    text-align: left;
    box-shadow: 0px 0px 10px 5px #d8d8d8;
    margin-bottom: 100px;
  }
  #nos_producteurs .fond .column .producteur:hover {
    transform: scale(1.1);
    transition: 1.5s;
  }
  #nos_producteurs .fond .column .producteur img {
    width: 100%;
  }
  #nos_producteurs .fond .column .producteur h3 {
    margin: 1.5vh 0;
  }
  #nos_producteurs .fond .column .producteur h5 {
    margin: 1vh 0;
  }
  #nos_producteurs .fond .column .producteur .padding {
    padding: 3vh;
  }
  #nos_producteurs .fond .column .producteur button {
    font-family: 'Bitter';
    color: white;
    padding: 2vh 4vh 2vh 4vh;
    background-color: #f05c34;
    border: none;
    border-radius: 40px;
  }
  #nos_producteurs .fond .column .producteur button:hover {
    background-color: #bfcf38;
    transition: 0.3s;
  }
  #nos_producteurs .fond .column .label {
    height: fit-content;
    text-align: center;
    margin: 0 0 150px 0;
    background-color: #bfcf38;
    padding: 0 5vh 5vh 5vh;
  }
  #nos_producteurs .fond .column .label p {
    font-family: 'Amatic SC';
    font-size: 2.5rem;
    color: white;
  }
  #nos_producteurs .fond .column .label img {
    margin-bottom: -120px;
  }
  #nos_producteurs .fond .column .label2 {
    height: fit-content;
    margin: auto;
    margin-bottom: 150px;
    background-color: #f8d644;
    padding: 0 5vh 5vh 5vh;
  }
  #nos_producteurs .fond .column .label2 p {
    font-family: 'Amatic SC';
    font-size: 2.5rem;
    color: white;
  }
  #nos_producteurs .fond .column .label2 img {
    margin-bottom: -120px;
  }
  #nos_producteurs .fond .column .label3 {
    height: fit-content;
    margin: auto;
    text-align: center;
    background-color: #bfcf38;
    padding: 0 5vh 5vh 5vh;
  }
  #nos_producteurs .fond .column .label3 p {
    font-family: 'Amatic SC';
    font-size: 2.5rem;
    color: white;
  }
  #nos_producteurs .fond .column .label3 img {
    margin-top: -80px;
  }
}
@media screen and (max-width: 960px) {
  #nos_producteurs .fond {
    padding: 0 5vw;
    width: 320px;
    margin: auto;
  }
  #nos_producteurs .producteur {
    margin-bottom: 10vh;
    box-shadow: 0px 0px 10px 5px #d8d8d8;
    margin-bottom: 100px;
  }
  #nos_producteurs .producteur .padding {
    padding: 5vw;
  }
  #nos_producteurs .producteur h3 {
    margin-top: 0;
  }
  #nos_producteurs .producteur img {
    width: 100%;
  }
  #nos_producteurs .producteur button {
    font-family: 'Bitter';
    color: white;
    padding: 2vh 4vh 2vh 4vh;
    background-color: #f05c34;
    border: none;
    border-radius: 40px;
  }
  #nos_producteurs .producteur button:hover {
    background-color: #bfcf38;
    transition: 0.3s;
  }
  #nos_producteurs .label {
    height: fit-content;
    width: 100%;
    text-align: center;
    background-color: #bfcf38;
    margin-bottom: 20vh;
    padding: 2.5vh 0;
  }
  #nos_producteurs .label p {
    font-family: 'Amatic SC';
    font-size: 2.5rem;
    color: white;
  }
  #nos_producteurs .label img {
    margin-bottom: -120px;
  }
  #nos_producteurs .label2 {
    width: 100%;
    height: fit-content;
    background-color: #f8d644;
    margin-bottom: 20vh;
    padding: 2.5vh 0;
  }
  #nos_producteurs .label2 p {
    font-family: 'Amatic SC';
    font-size: 2.5rem;
    color: white;
  }
  #nos_producteurs .label2 img {
    margin-bottom: -110px;
  }
  #nos_producteurs .label3 {
    height: fit-content;
    width: 100%;
    text-align: center;
    margin-top: 20vh;
    padding: 2.5vh 0;
    background-color: #bfcf38;
  }
  #nos_producteurs .label3 p {
    font-family: 'Amatic SC';
    font-size: 2.5rem;
    color: white;
  }
  #nos_producteurs .label3 img {
    margin-top: -80px;
  }
}
