@media screen and (min-width: 960px) {
  #corbeillefruit {
    margin-bottom: 50px;
  }
  #corbeillefruit .row {
    text-align: left;
    align-items: flex-start;
  }
  #corbeillefruit .row img {
    height: 60px;
    margin-right: 10px;
  }
  #corbeillefruit .row p:nth-child(2) {
    font-family: 'Amatic SC';
    font-size: 2rem;
    margin: 0;
    margin-bottom: 40px;
  }
  #corbeillefruit .row p:nth-child(1) {
    font-family: 'Amatic SC';
    color: #f05c34;
    font-size: 3rem;
    margin: 0;
  }
  #corbeillefruit .row span {
    color: #bfcf38;
    font-size: 1.8rem;
    font-family: 'Amatic SC';
    margin-right: 10px;
  }
  #corbeillefruit .list {
    display: flex;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 50px;
  }
  #corbeillefruit .list img {
    margin-right: 20px;
    height: 250px;
  }
  #corbeillefruit .list span {
    color: #bfcf38;
    font-size: 1.8rem;
    font-family: 'Amatic SC';
    margin-right: 10px;
  }
}
@media screen and (max-width: 960px) {
  #corbeillefruit button:nth-child(1) {
    margin-bottom: 50px;
  }
  #corbeillefruit .row {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }
  #corbeillefruit .row img {
    height: 60px;
    margin-right: 10px;
  }
  #corbeillefruit .row p:nth-child(2) {
    font-family: 'Amatic SC';
    font-size: 5.2vw;
    margin: 0;
    margin-bottom: 40px;
  }
  #corbeillefruit .row p:nth-child(1) {
    font-family: 'Amatic SC';
    color: #f05c34;
    font-size: 8vw;
    margin: 0;
  }
  #corbeillefruit .row span {
    color: #bfcf38;
    font-size: 1.8rem;
    font-family: 'Amatic SC';
    margin-right: 10px;
  }
  #corbeillefruit .list {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 50px;
  }
  #corbeillefruit .list img {
    margin-right: 20px;
    height: 250px;
  }
  #corbeillefruit .list span {
    color: #bfcf38;
    font-size: 1.8rem;
    font-family: 'Amatic SC';
    margin-right: 10px;
  }
}
