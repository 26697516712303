.hidden {
  position: fixed !important;
  width: 100%;
  top: 0;
  margin-top: -251px; /*-130px;*/
  transition: margin-top 0.3s;
  background-color: white;
  z-index: 100;
}

.show {
  position: fixed !important;
  width: 100%;
  top: 0;
  transition: margin-top 0.3s;
  background-color: white;
  z-index: 100;
}

.marginTop {
  margin-top: 160px;
}

@media screen and (max-width: 960px) {
  .marginTop {
    margin-top: 147px;
  }
}