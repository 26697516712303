#ecopoints {
  max-width: 1400px;
  margin: 0 auto;
}
@media screen and (min-width: 960px) {
  #ecopoints .schema {
    padding: 0 !important;
  }
  #ecopoints .schema h3 {
    margin: 0.5rem 0 !important;
  }
  #ecopoints .schema h4 {
    width: 15vw;
  }
  #ecopoints .schema #egal {
    margin-top: -40px !important;
  }
  #ecopoints .row {
    justify-content: space-between;
  }
  #ecopoints .row p {
    font-size: 1rem;
  }
  #ecopoints .row img {
    width: 15vw;
  }
  #ecopoints .row div {
    text-align: justify;
    display: flex;
    flex-direction: column;
    margin-left: 2vw;
  }
  #ecopoints .row .client {
    color: #87b07a;
  }
}
@media screen and (max-width: 960px) {
  #ecopoints .schema {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8vh 0;
    padding: 0 !important;
  }
  #ecopoints .schema h3 {
    margin: 0.5rem 0 !important;
  }
  #ecopoints .schema h4 {
    width: 40vw;
  }
  #ecopoints .schema #egal {
    margin-top: -40px !important;
  }
  #ecopoints .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  #ecopoints .row p {
    font-size: 1rem;
  }
  #ecopoints .row img {
    width: 205px;
  }
  #ecopoints .row div {
    text-align: justify;
    display: flex;
    flex-direction: column;
    margin-left: 2vw;
  }
  #ecopoints .row .client {
    color: #87b07a;
  }
}