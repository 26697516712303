@charset "UTF-8";
#ccm #montagne {
  width: 100%;
}
@media screen and (min-width: 960px) {
  #ccm h3 {
    margin: 0 0 10px 0;
  }
  #ccm .section {
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: fit-content;
    background-color: white;
    max-width: 40vw;
    box-shadow: 0px 0px 10px 5px #d8d8d8;
  }
  #ccm .section li {
    list-style: "🍊";
    margin-bottom: 5px;
  }
  #ccm .illu {
    background-image: url(./../../assets/img/backgrounds/liane.png);
    background-repeat: no-repeat;
    background-position-y: 200px;
    background-position-x: 35px;
    background-size: contain;
    max-width: 1000px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    margin-bottom: -150px;
    padding-bottom: 200px;
  }
  #ccm .illu img {
    width: 300px;
  }
  #ccm .illu .line:nth-child(1) {
    justify-content: space-between;
  }
  #ccm .illu .line:nth-child(2) {
    justify-content: space-between;
  }
  #ccm .illu .line:nth-child(6) img {
    width: 10vw;
  }
  #ccm .illu .line {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5vw 0;
  }
  #ccm li {
    padding-left: 0.5rem;
  }
  #ccm .bande {
    padding: 5vh 0;
    margin-top: -7px;
    background-color: #bfcf38;
  }
  #ccm .bande p {
    color: white;
  }
}
@media screen and (max-width: 960px) {
  #ccm .section {
    margin: 5vw;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 0px 10px 5px #d8d8d8;
  }
  #ccm .section li {
    list-style: "🍊";
    margin-bottom: 5px;
  }
  #ccm .illu {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 8vw;
    margin-bottom: -150px;
    padding-bottom: 200px;
  }
  #ccm .illu img {
    width: 25vw;
  }
  #ccm .illu .line:nth-child(1) {
    justify-content: space-between;
  }
  #ccm .illu .line:nth-child(2) {
    justify-content: space-between;
  }
  #ccm .illu .line:nth-child(6) img {
    width: 100px;
  }
}