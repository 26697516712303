#shipping .shipping {
  text-align: center;
  width: fit-content;
  padding: 10px;
  position: fixed;
  z-index: 100;
  bottom: 2vw;
  right: 2vw;
  background-color: #f05c34;
  border-radius: 35px;
}
#shipping .shipping > button {
  padding: 0;
}
#shipping .shipping p {
  color: white;
  margin: 1vh 0 2vh 0;
}
#shipping .row {
  padding: 0 !important;
}
#shipping .search {
  background-color: white;
  padding: 5px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#shipping .search input {
  border: none;
  outline: none;
  padding-left: 1rem;
  font-size: 20px;
}
#shipping .search input::placeholder {
  font-size: 13px;
}
#shipping .MuiStepLabel-label .MuiStepLabel-active {
  font-family: "Bitter";
}
@media (max-width: 960px) and (min-width: 0px) {
  #shipping .shipping {
    width: auto;
  }
  #shipping .shipping div:nth-child(1) {
    display: none;
  }
}
@media screen and (min-width: 960px) {
  #shipping .shipping .div2 {
    display: none;
  }
}