@media screen and (max-width: 960px) {
  #zero_dechets .section {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #zero_dechets .section div {
    margin: 0 2vw;
  }
  #zero_dechets .section .fleche {
    width: 10%;
  }
  #zero_dechets .section p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  #zero_dechets .cadre {
    background-color: #bfcf38;
    margin-top: -5px;
  }
  #zero_dechets .cadre p {
    color: white;
    margin: -40px 20vw;
    font-size: 0.9rem;
    margin: 0 10vw 0 10vw;
    word-spacing: 7px;
  }
  #zero_dechets .cadre h3 {
    font-size: 5vw;
    color: white;
  }
  #zero_dechets .cadre img:first-of-type {
    width: 30vw;
    margin-left: -65% !important;
    margin-top: -9vw;
    margin-bottom: -30px;
  }
  #zero_dechets .cadre img:nth-of-type(2n) {
    width: 30vw;
    margin-right: -65% !important;
    margin-top: -30px;
    margin-bottom: -15vw;
  }
}
#zero_dechets .container {
  margin: 0 20vw;
}
@media screen and (min-width: 960px) {
  #zero_dechets .section {
    margin: 0;
    display: flex;
    justify-content: center;
  }
  #zero_dechets .section div {
    margin: 0 2vw;
    width: 25%;
  }
  #zero_dechets .section .fleche {
    width: 10%;
  }
  #zero_dechets .section p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  #zero_dechets .cadre {
    background-color: #bfcf38;
    margin-top: -5px;
  }
  #zero_dechets .cadre div {
    margin: -90px 0;
  }
  #zero_dechets .cadre p {
    color: white;
    margin: -40px 20vw;
  }
  #zero_dechets .cadre img:first-of-type {
    margin-left: -65% !important;
    margin-top: -120px;
  }
  #zero_dechets .cadre img:nth-of-type(2n) {
    margin-right: -65% !important;
    margin-bottom: -150px;
  }
  #zero_dechets .picto {
    margin: 0;
    display: flex;
    justify-content: center;
  }
  #zero_dechets .picto div {
    margin: 0 2vw;
    width: 25%;
  }
  #zero_dechets .picto .fleche {
    width: 10%;
    margin-top: 60px;
  }
  #zero_dechets .picto p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
}