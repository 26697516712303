#profil {
  background-color: #bfcd46;
  padding: 94px 3vw 3vw 3vw;
  text-align: left;
}
#profil #h3 {
  font-size: 4rem;
  padding: 0 0 2rem 0;
  color: white;
}
#profil #contact {
  color: #bfcf38;
  font-weight: 700;
}
#profil .compte {
  display: flex;
  flex-direction: row;
}
#profil #enregistrer {
  margin: 10px 20px 10px 35px;
}
@media (min-width: 0px) and (max-width: 960px) {
  #profil #enregistrer {
    margin: 10px 20px 10px 25px;
  }
}
#profil h3 {
  margin: 0;
}
#profil section {
  background-color: white;
  width: 94%;
  text-align: left;
  padding: 3vw;
}
#profil .adresses {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
#profil .adresses .form {
  width: 50%;
  text-align: left;
  margin: auto;
}
#profil .adresses .form .input {
  margin: 2px;
}
@media (min-width: 0px) and (max-width: 960px) {
  #profil .adresses {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  #profil .adresses .form {
    width: 60%;
    text-align: center;
    margin: auto;
  }
  #profil .adresses .form .input {
    margin: 2px;
  }
}
#profil .option {
  margin-right: 2vw;
  width: 18%;
}
#profil .infos {
  width: 59%;
}
#profil .MuiDivider-root {
  margin: 0 12px;
  background-color: #e0e0e0;
  border: none;
  padding: 0;
}
#profil .MuiList-root:hover {
  background-color: #e0e0e0;
  transition: 0.3s;
}