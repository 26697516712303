@-webkit-keyframes bounceLinks {
  0% {
    transform: translateY(0em);
  }
  20% {
    transform: translateY(-0.2em);
  }
  80% {
    transform: translateY(0.2em);
  }
  100% {
    transform: translateY(0em);
  }
}
@-moz-keyframes bounceLinks {
  0% {
    transform: translateY(0em);
  }
  20% {
    transform: translateY(-0.2em);
  }
  80% {
    transform: translateY(0.2em);
  }
  100% {
    transform: translateY(0em);
  }
}
@-o-keyframes bounceLinks {
  0% {
    transform: translateY(0em);
  }
  20% {
    transform: translateY(-0.2em);
  }
  80% {
    transform: translateY(0.2em);
  }
  100% {
    transform: translateY(0em);
  }
}
@keyframes bounceLinks {
  0% {
    transform: translateY(0em);
  }
  20% {
    transform: translateY(-0.2em);
  }
  80% {
    transform: translateY(0.2em);
  }
  100% {
    transform: translateY(0em);
  }
}
#select {
  width: 100%;
}

.btn-orange {
  outline: none;
  cursor: pointer;
  font-family: "Bitter";
  text-transform: inherit;
  color: white;
  padding: 15px;
  font-size: 1rem;
  background-color: #ADC00D;
  border: none;
  border-radius: 40px;
}
.btn-orange:hover {
  background-color: #bfcf38;
  transition: 0.3s;
}

#navbareco {
  background-color: #e5e5e5;
}

.modal {
  background-color: white;
  width: fit-content;
  margin: 20% auto;
  padding: 2rem;
  text-align: center;
}
.modal h2 {
  margin-top: 0;
  color: #f05c34;
}
.modal input {
  font-size: 1.5rem;
  border: 2px solid #f05c34;
  border-radius: 20px;
}
.modal .code {
  display: flex;
}

.cadre .menu {
  list-style: none;
  padding: 0;
}
.cadre .menu li {
  padding: 10px 10px;
  text-transform: uppercase;
  color: #7c7c7c;
  font-size: 1.2rem;
  list-style: none;
}
.cadre .menu li:hover {
  padding: 10px 10px;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 30px;
  background-color: #f05c34;
}
.cadre .menu li:hover a:hover {
  color: white;
}
.cadre hr {
  margin: 0 3vw;
  border: 1px solid #7c7c7c;
  background-color: #7c7c7c;
}
.cadre .menu-secondaire {
  background-color: #f05c34;
  padding: 0;
}
.cadre .menu-secondaire li {
  list-style: none;
  padding: 2vw 3vw;
  color: white;
  font-family: "Amatic SC";
  font-size: 2rem;
}

#header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 80px;
  transition: top 0.3s;
  transition-delay: 0.2s;
}

#header.hidden {
  top: -172px;
}

.ecocadre {
  width: 350px;
  background-color: #a0c868;
  height: 100vh;
}
.ecocadre .menu {
  list-style: none;
  padding: 0;
  padding-right: 2rem;
}
.ecocadre .menu li {
  padding: 10px 10px;
  text-transform: uppercase;
  color: white;
  font-size: 1.2rem;
  list-style: none;
}
.ecocadre .menu li a {
  color: white;
}
.ecocadre .menu li:hover {
  padding: 10px 10px;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 30px;
  background-color: #f05c34;
}
.ecocadre .menu li:hover a:hover {
  color: white;
}
.ecocadre hr {
  margin: 0 3vw;
  border: 1px solid white;
  background-color: white;
}
.ecocadre .menu-secondaire {
  background-color: #f05c34;
  padding: 0;
}
.ecocadre .menu-secondaire li {
  list-style: none;
  padding: 2vw 3vw;
  color: white;
  font-family: "Amatic SC";
  font-size: 2rem;
}

a {
  text-decoration: none;
}

.nav-navbar {
  max-width: 1200px;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}
.nav-navbar figure {
  margin: 0;
  max-width: 90px;
}
.nav-navbar figure img {
  width: 100%;
}
.nav-navbar > ul > li > a {
  text-transform: uppercase;
}
.nav-navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}
.nav-navbar ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #7c7c7c;
}
.nav-navbar ul li:hover ul.dropdown {
  z-index: 1000;
  display: block;
}
.nav-navbar ul li:hover a {
  color: #f05c34;
}
.nav-navbar ul li ul.dropdown {
  position: absolute;
  display: none;
  width: fit-content;
  background: white;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding-top: 0;
}
.nav-navbar ul li ul.dropdown li {
  display: block;
  list-style-type: none;
  white-space: nowrap;
  text-align: center;
  border-top: 1px solid #ececec;
}
.nav-navbar ul li ul.dropdown li a {
  padding: 15px 10px;
  font-size: 15px;
  color: #7c7c7c;
  display: block;
  font-weight: 400;
}
.nav-navbar ul li ul.dropdown li a:hover {
  color: #f05c34;
}
.nav-navbar ul li a {
  padding: 15px 15px;
  font-size: 14;
  color: #7c7c7c;
  display: inline-block;
  outline: 0;
  font-weight: 400;
}
.nav-navbar .nav-pictos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 80px;
}

.nav-navbar .nav-pictos .MuiSvgIcon-root {
  cursor: pointer;
  font-size: 1.5em;
  fill: #adc00d;
}
@media only screen and (max-width: 450px) {
  .nav-navbar .nav-pictos .MuiSvgIcon-root {
    cursor: pointer;
    font-size: 1em;
  }

  .nav-navbar figure {
    margin: 0;
    max-width: 54px;
  }
}
.nav-navbar .nav-pictos .MuiSvgIcon-root:hover {
  fill: #7c7c7c;
}

.nav-navbareco {
  z-index: 0;
  max-width: 1200px;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
}
.nav-navbareco figure {
  margin: 0;
  max-width: 90px;
}
.nav-navbareco figure img {
  width: 100%;
}
.nav-navbareco > ul > li > a {
  text-transform: uppercase;
}
.nav-navbareco ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}
.nav-navbareco ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #7c7c7c;
}
.nav-navbareco ul li:hover ul.dropdown {
  z-index: 1000;
  display: block;
}
.nav-navbareco ul li:hover a {
  color: #f05c34;
}
.nav-navbareco ul li ul.dropdown {
  position: absolute;
  display: none;
  width: fit-content;
  background: #e5e5e5;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding-top: 0;
}
.nav-navbareco ul li ul.dropdown li {
  display: block;
  list-style-type: none;
  white-space: nowrap;
  text-align: center;
  border-top: 1px solid white;
}
.nav-navbareco ul li ul.dropdown li a {
  padding: 15px 10px;
  font-size: 15px;
  color: #7c7c7c;
  display: block;
  font-weight: 400;
}
.nav-navbareco ul li ul.dropdown li a:hover {
  color: #f05c34;
}
.nav-navbareco ul li a {
  vertical-align: center;
  padding: 15px 15px;
  font-size: 14;
  color: #7c7c7c;
  display: inline-block;
  outline: 0;
  font-weight: 400;
}
.nav-navbareco .nav-pictos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 80px;
}
.nav-navbareco .nav-pictos .MuiSvgIcon-root {
  cursor: pointer;
  font-size: 1.5em;
  fill: #adc00d;
}
.nav-navbareco .nav-pictos .MuiSvgIcon-root:hover {
  fill: #7c7c7c;
}

@media (max-width: 400px) and (min-width: 0) {
  .nav-navbar2 {
    background-color: #f05c34;
    padding: 1rem 0;
    font-size: 1.5rem;
  }
  .nav-navbar2 hr:last-child {
    display: none;
  }
  .nav-navbar2 ul {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-navbar2 ul li {
    list-style: none;
  }
  .nav-navbar2 ul li:hover {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounceLinks;
    -moz-animation-name: bounceLinks;
    -o-animation-name: bounceLinks;
    animation-name: bounceLinks;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
  }
  .nav-navbar2 ul li a {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 1.1rem;
    text-decoration: none;
    color: white;
  }
}
@media (max-width: 660px) and (min-width: 400px) {
  .nav-navbar2 {
    background-color: #f05c34;
    padding: 1rem 0;
    font-size: 1.5rem;
  }
  .nav-navbar2 hr:last-child {
    display: none;
  }
  .nav-navbar2 ul {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-navbar2 ul li {
    list-style: none;
  }
  .nav-navbar2 ul li:hover {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounceLinks;
    -moz-animation-name: bounceLinks;
    -o-animation-name: bounceLinks;
    animation-name: bounceLinks;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
  }
  .nav-navbar2 ul li a {
    font-family: "Amatic SC", cursive;
    font-size: 1.2rem;
    text-decoration: none;
    color: white;
  }
}
@media (max-width: 1000px) and (min-width: 660px) {
  .nav-navbar2 {
    background-color: #f05c34;
    padding: 1rem 0;
    font-size: 1.5rem;
  }
  .nav-navbar2 hr:last-child {
    display: none;
  }
  .nav-navbar2 ul {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-navbar2 ul li {
    list-style: none;
  }
  .nav-navbar2 ul li:hover {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounceLinks;
    -moz-animation-name: bounceLinks;
    -o-animation-name: bounceLinks;
    animation-name: bounceLinks;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
  }
  .nav-navbar2 ul li a {
    font-family: "Amatic SC", cursive;
    font-size: 1.2em;
    text-decoration: none;
    color: white;
  }
}
@media (max-width: 1250px) and (min-width: 1000px) {
  .nav-navbar2 {
    background-color: #f05c34;
    padding: 1rem 0;
    font-size: 1.5rem;
  }
  .nav-navbar2 hr:last-child {
    display: none;
  }
  .nav-navbar2 ul {
    max-width: 65%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-navbar2 ul li {
    list-style: none;
  }
  .nav-navbar2 ul li:hover {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounceLinks;
    -moz-animation-name: bounceLinks;
    -o-animation-name: bounceLinks;
    animation-name: bounceLinks;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
  }
  .nav-navbar2 ul li a {
    font-family: "Amatic SC", cursive;
    font-size: 1.5em;
    text-decoration: none;
    color: white;
  }
}
@media screen and (min-width: 1250px) {
  .nav-navbar2 {
    background-color: #f05c34;
    padding: 1rem 0;
    font-size: 1.5rem;
  }
  .nav-navbar2 hr:last-child {
    display: none;
  }
  .nav-navbar2 ul {
    max-width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-navbar2 ul li {
    list-style: none;
  }
  .nav-navbar2 ul li:hover {
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounceLinks;
    -moz-animation-name: bounceLinks;
    -o-animation-name: bounceLinks;
    animation-name: bounceLinks;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
  }
  .nav-navbar2 ul li a {
    font-family: "Amatic SC", cursive;
    font-size: 1.5em;
    text-decoration: none;
    color: white;
  }
}
.produit {
  margin: 1rem;
}
.produit .flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.produit .flexrow .img {
  width: 40%;
  max-width: 200px;
  margin-right: 20px;
}
.produit .flexcolumn {
  width: 50%;
}

.total {
  text-align: center;
}

.nbProd {
  text-align: center;
}