@charset "UTF-8";
@media screen and (min-width: 960px) {
  #nos_produits .container {
    margin: 0 20vw;
  }
  #nos_produits .schema .span {
    background-image: url(./../img/fond-patate.png);
    background-repeat: no-repeat;
    margin: 10px;
    width: 22%;
    padding: 80px 10px 94px 10px;
  }
  #nos_produits .schema p {
    font-family: 'Amatic SC';
    font-size: 3.5vw;
    font-weight: lighter;
    color: #f05c33;
    margin: 0;
  }
  #nos_produits .row ul {
    width: 70%;
    text-align: left;
  }
  #nos_produits .text {
    width: 70%;
    text-align: left;
  }
  #nos_produits .li {
    list-style-type: '🍊';
    padding-left: 1rem;
  }
  #nos_produits #producteur {
    max-width: 600px;
  }
  #nos_produits figure {
    background-image: url(./../img/fond-patate.png);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 215px;
  }
  #nos_produits figure figcaption {
    font-family: 'Amatic SC';
    font-size: 2em;
    font-weight: lighter;
    color: #f05c33;
  }
  #nos_produits .citation {
    word-spacing: 10px;
  }
}
@media screen and (max-width: 960px) {
  #nos_produits .container {
    margin: 0 10vw;
  }
  #nos_produits .schema {
    flex-direction: column;
    padding: 0;
  }
  #nos_produits .schema .span {
    background-image: url(./../img/fond-patate.png);
    background-repeat: no-repeat;
    margin: 10px;
    width: 22%;
    padding: 80px 10px 94px 10px;
  }
  #nos_produits .schema p {
    font-family: 'Amatic SC';
    font-size: 4.5rem;
    font-weight: lighter;
    color: #bfcf38;
    margin-top: -30px;
    margin-bottom: -20px;
  }
  #nos_produits .row {
    flex-direction: column;
  }
  #nos_produits .row ul {
    width: 70%;
    text-align: left;
  }
  #nos_produits .text {
    width: 70%;
    text-align: left;
  }
  #nos_produits .li {
    list-style-type: '🍊';
    padding-left: 1rem;
  }
  #nos_produits #producteur {
    width: 100%;
  }
  #nos_produits figure {
    background-image: url(./../img/fond-patate.png);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 215px;
  }
  #nos_produits figure figcaption {
    font-family: 'Amatic SC';
    font-size: 2em;
    font-weight: lighter;
    color: #bfcf38;
  }
}
