#offreentreprise {
  padding-bottom: 50px;
}
@media screen and (min-width: 960px) {
  #offreentreprise .row {
    text-align: left;
    align-items: flex-start;
  }
  #offreentreprise .row img {
    height: 80px;
    margin-right: 10px;
  }
  #offreentreprise .row p:nth-child(1) {
    font-family: 'Amatic SC';
    font-size: 2rem;
    margin: 0;
  }
  #offreentreprise .row p:nth-child(2) {
    font-family: 'Amatic SC';
    color: #f05c34;
    font-size: 3rem;
    margin: 0;
    margin-bottom: 40px;
  }
  #offreentreprise .list {
    display: flex;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 50px;
  }
  #offreentreprise .list img {
    margin-right: 20px;
    height: 150px;
  }
  #offreentreprise .list span {
    color: #bfcf38;
    font-size: 1.8rem;
    font-family: 'Amatic SC';
  }
}
@media screen and (max-width: 960px) {
  #offreentreprise h1 {
    font-size: 14vw;
  }
  #offreentreprise .row {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  #offreentreprise .row p:nth-child(1) {
    font-family: 'Amatic SC';
    font-size: 5.2vw;
    margin: 0;
  }
  #offreentreprise .row p:nth-child(2) {
    font-family: 'Amatic SC';
    color: #f05c34;
    font-size: 8vw;
    margin: 0;
    margin-bottom: 40px;
  }
  #offreentreprise .list {
    text-align: left;
  }
  #offreentreprise .list img {
    width: 100%;
  }
  #offreentreprise .list span {
    color: #bfcf38;
    font-size: 1.8rem;
    font-family: 'Amatic SC';
  }
}
