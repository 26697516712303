body {
  margin: 0;
  padding: 0;
  font-family: "Bitter";
  color: #7c7c7c;
}

.d_block {
  display: block;
}

body {
  font-family: "Bitter";
}

.front {
  margin: 0;
  padding: 0;
  font-family: "Bitter";
  text-align: center;
}
.front body {
  font-family: "Bitter";
}
.front h1 {
  font-family: "Amatic SC";
  color: #bfcf38;
  font-size: 5rem;
  margin-bottom: 0;
}
.front h2 {
  font-family: "Amatic SC";
  font-size: 2rem;
  color: #bfcf38;
  margin-top: 0;
}
.front h3 {
  font-family: "Amatic SC";
  font-size: 2.8em;
  color: #f05c34;
}
.front h4 {
  font-family: "Amatic SC";
  font-size: 1.8em;
  color: #87b07a;
}
.front h5 {
  font-family: "Amatic SC";
  font-size: 1.8em;
  font-weight: 700;
  color: #f05c34;
  margin: 0 !important;
}
.front a {
  font-family: "Bitter";
}
.front span {
  font-family: "Bitter";
}
.front li {
  list-style: none;
}
.front .btn-orange {
  outline: none;
  cursor: pointer;
  font-family: "Bitter";
  text-transform: inherit;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  background-color: #f05c34;
  border: none;
  border-radius: 40px;
}
.front .btn-orange:hover {
  background-color: #ADC00D;
  transition: 0.3s;
}
.front #search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.front .buttonprofil {
  background-color: unset;
  padding: 1rem;
}
.front .buttonprofil:hover {
  background-color: unset;
}
.front .row {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 5vw 0;
  justify-content: space-between;
}
.front .schema {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vh 0;
}
.front .container {
  margin: 0 15vw;
}
.front .section {
  padding: 2rem;
  margin: 1vw;
}
.front .shipping {
  position: fixed;
  background-color: red;
}
.front #cgv .container {
  margin: 0 25vw;
  text-align: justify;
}
@media screen and (min-width: 960px) {
  .front .section {
    display: flex;
    flex-flow: row;
    width: auto;
    margin: 0 5vw;
    justify-content: center;
  }
  .front .section section {
    padding: 2rem;
    margin: 1vw;
    box-shadow: 0px 0px 10px 5px #d8d8d8;
    max-width: 200px;
  }
  .front .section section p {
    text-align: left;
    font-size: 0.9rem;
  }
  .front .section ul {
    text-align: left;
    margin-left: -1.5rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 960px) {
  .front .section {
    display: flex;
    flex-flow: column;
  }
  .front .section section {
    flex-grow: 1;
    box-shadow: 0px 0px 10px 5px #d8d8d8;
    margin: 2vh;
    padding: 5vh;
  }
}