#contact {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#contact .appel {
  background-image: url(../img/fond-patate.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 300px;
  margin: 6rem 0;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#contact .appel .num {
  margin: 0;
  font-family: "Amatic SC", cursive;
  font-size: 3rem;
  color: #adc00c;
}
#contact .p {
  width: 50%;
  margin: 0 auto 2rem auto;
}
#contact .span {
  text-align: left;
  color: #f05c35;
}
#contact .form {
  max-width: 700px;
  display: flex;
  margin-left: 5%;
  flex-direction: column;
}
#contact .form .input {
  margin: 1rem 0;
}
#contact .form button {
  width: fit-content;
}